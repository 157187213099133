import hash from 'blueimp-md5'
import { createSchema, _ } from 'tiny-schema-validator'

const coilsDefaultValues = {
  coilGeometryId: 1,
  tubeMaterialId: 3, // Cu (Copper)
  tubeTypeId: 1,
  tubeThickness: '0.3',
  finMaterialId: 1, // Al (Al)
  finTypeId: 1,
  finThickness: '0.10',
  finSpacing: '2.1',
  tubesQuantity: 40,
  rowsQuantity: 6,
  finnedCoilLength: '1400',
  tubeSeriesNumber: null,
  coilsQuantity: 1
}

const fluidDefaultValues = {
  fluidType: null,
  condensingTemperature: '40.0',
  gasTemperature: '75.0',
  subCoolingDeltaTemperature: '3.0',
  inputTemperature: '40.0',
  inputTemperatureCool: '40.0',
  inputTemperatureHot: '1.0',
  outputTemperature: '35.0',
  outputTemperatureCool: '35.0',
  outputTemperatureHot: '5.0'
}

const fluidLTDefaultValues = {
  fluidType: 6,
  inputTemperature: '40.0',
  outputTemperature: '35.0'
}

const fluidHTDefaultValues = {
  fluidType: 6,
  inputTemperature: '50.0',
  outputTemperature: '45.0'
}

const dutyDefaultValues = {
  doubleCircuitOption: false,
  maxLiquidPressureDrop: '80.0',
  secondCircuitCapacity: null,
  waterFlow: null,
  internalFoulingFactor: '0',
  externalFoulingFactor: '0'
}

const dutyLTHTDefaultValues = {
  maxLiquidPressureDrop: '80.0',
  secondCircuitCapacity: null,
  waterFlow: null,
  internalFoulingFactor: '0',
  externalFoulingFactor: '0'
}

export const customConfiguratorDefaults = {
  productType: null,
  previouslySelectedProductType: null,
  condition: 'drySystem',
  priority: 'heating',
  productConfig: null,
  coils: { ...coilsDefaultValues },
  coilsLT: { ...coilsDefaultValues },
  coilsHT: { ...coilsDefaultValues },
  air: {
    inputTemperature: '25.0',
    relativeHumidity: '50.0',
    inputTemperatureCool: '25.0',
    relativeHumidityCool: '50.0',
    inputTemperatureHot: '15.0',
    relativeHumidityHot: '30.0',
    altitude: '0',
    externalStaticPressure: '0'
  },
  fluid: { ...fluidDefaultValues },
  fluidLT: { ...fluidLTDefaultValues },
  fluidHT: { ...fluidHTDefaultValues },
  duty: { ...dutyDefaultValues },
  dutyLT: { ...dutyLTHTDefaultValues },
  dutyHT: { ...dutyLTHTDefaultValues },
  fans: {
    fanId: 1,
    quantity: 1,
    rowsQuantity: 1,
    fanPercentage: 100,
    filterName: null,
    mainTypeSlug: 'AC'
  },
  ventilationNoise: {
    distance: '10',
    noiseCalculationModel: 'v1'
  },
  dimensions: {
    length: '1000',
    height: '1000',
    depth: '1000'
  },
  unitSystem: 'SI',
  // UI flags
  ui: {
    showForm: false,
    allowSubmit: false,
    air: {
      show: {
        cool: false,
        hot: false
      }
    },
    fluid: {
      show: {
        gas: false,
        cool: false,
        hot: false
      }
    },
    duty: {
      show: {
        doubleCircuitOption: false,
        maxLiquidPressureDrop: false,
        waterFlow: false
      },
      disableMaxLiquidPressureDrop: false,
      disableSurfaceMargin: false
    },
    fans: {
      disableFanPercentage: false
    },
    showPriority: false,
    selectOptions: {
      coilGeometries: [],
      coilGeometriesLT: [],
      coilGeometriesHT: [],
      tubeMaterials: [],
      tubeMaterialsLT: [],
      tubeMaterialsHT: [],
      tubeTypes: [],
      tubeTypesLT: [],
      tubeTypesHT: [],
      finMaterials: [],
      finMaterialsLT: [],
      finMaterialsHT: [],
      finTypes: [],
      finTypesLT: [],
      finTypesHT: [],
      refrigerants: [],
      fans: []
    }
  },
  errors: {}
}

customConfiguratorDefaults.version = hash(JSON.stringify(customConfiguratorDefaults))

const customCoilsSchema = {
  coilGeometryId: _.number(),
  tubeMaterialId: _.number(),
  tubeTypeId: _.number(),
  tubeThickness: _.string(),
  finMaterialId: _.number(),
  finTypeId: _.number(),
  finThickness: _.string(),
  finSpacing: _.string(),
  tubesQuantity: _.number(),
  rowsQuantity: _.number(),
  finnedCoilLength: _.string(),
  tubeSeriesNumber: _.number({ optional: true }),
  coilsQuantity: _.number()
}

const customFluidSchema = {
  fluidType: _.number({ optional: true }),
  condensingTemperature: _.string(),
  gasTemperature: _.string(),
  subCoolingDeltaTemperature: _.string(),
  inputTemperature: _.string(),
  inputTemperatureCool: _.string(),
  inputTemperatureHot: _.string(),
  outputTemperature: _.string(),
  outputTemperatureCool: _.string(),
  outputTemperatureHot: _.string()
}

const customFluidLTHTSchema = {
  fluidType: _.number({ optional: true }),
  inputTemperature: _.string(),
  outputTemperature: _.string()
}

const customDutySchema = {
  doubleCircuitOption: _.boolean(),
  maxLiquidPressureDrop: _.string(),
  secondCircuitCapacity: _.string({ optional: true }),
  waterFlow: _.string({ optional: true }),
  internalFoulingFactor: _.string(),
  externalFoulingFactor: _.string()
}

const customDutyLTHTSchema = {
  maxLiquidPressureDrop: _.string(),
  secondCircuitCapacity: _.string({ optional: true }),
  waterFlow: _.string({ optional: true }),
  internalFoulingFactor: _.string(),
  externalFoulingFactor: _.string()
}

const selectOptionSchema = {
  label: _.string(),
  value: _.number()
}

export const CustomConfiguratorSchema = {
  productId: _.number({ optional: true }),
  productType: _.string({ optional: true }),
  previouslySelectedProductType: _.string({ optional: true }),
  productConfig: _.record({
    id: _.number(),
    familyId: _.number(),
    familyName: _.string(),
    familyCode: _.string(),
    name: _.string(),
    fanMainType: _.string()
  }, { optional: true }),
  coils: _.record(customCoilsSchema),
  coilsLT: _.record(customCoilsSchema),
  coilsHT: _.record(customCoilsSchema),
  condition: _.string(),
  priority: _.string(),
  air: _.record({
    inputTemperature: _.string(),
    relativeHumidity: _.string(),
    inputTemperatureCool: _.string(),
    relativeHumidityCool: _.string(),
    inputTemperatureHot: _.string(),
    relativeHumidityHot: _.string(),
    altitude: _.string(),
    externalStaticPressure: _.string()
  }),
  fluid: _.record(customFluidSchema),
  fluidLT: _.record(customFluidLTHTSchema),
  fluidHT: _.record(customFluidLTHTSchema),
  duty: _.record(customDutySchema),
  dutyLT: _.record(customDutyLTHTSchema),
  dutyHT: _.record(customDutyLTHTSchema),
  fans: _.record({
    fanId: _.number(),
    quantity: _.number(),
    rowsQuantity: _.number(),
    fanPercentage: _.number(),
    filterName: _.string({ optional: true }),
    mainTypeSlug: _.string({ optional: true })
  }),
  ventilationNoise: _.record({
    distance: _.string(),
    noiseCalculationModel: _.string()
  }),
  dimensions: _.record({
    length: _.string(),
    height: _.string(),
    depth: _.string()
  }),
  unitSystem: _.string(),
  ui: _.record({
    showForm: _.boolean(),
    allowSubmit: _.boolean(),
    air: _.record({
      show: _.record({
        cool: _.boolean(),
        hot: _.boolean()
      })
    }),
    fluid: _.record({
      show: _.record({
        gas: _.boolean(),
        cool: _.boolean(),
        hot: _.boolean()
      })
    }),
    duty: _.record({
      show: _.record({
        doubleCircuitOption: _.boolean(),
        maxLiquidPressureDrop: _.boolean(),
        waterFlow: _.boolean()
      }),
      disableMaxLiquidPressureDrop: _.boolean(),
      disableSurfaceMargin: _.boolean()
    }),
    fans: _.record({
      disableFanPercentage: _.boolean()
    }),
    showPriority: _.boolean(),
    selectOptions: _.record({
      coilGeometries: _.listof(_.record(selectOptionSchema, { optional: true })),
      coilGeometriesLT: _.listof(_.record(selectOptionSchema, { optional: true })),
      coilGeometriesHT: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeMaterials: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeMaterialsLT: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeMaterialsHT: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeTypes: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeTypesLT: _.listof(_.record(selectOptionSchema, { optional: true })),
      tubeTypesHT: _.listof(_.record(selectOptionSchema, { optional: true })),
      finMaterials: _.listof(_.record(selectOptionSchema, { optional: true })),
      finMaterialsLT: _.listof(_.record(selectOptionSchema, { optional: true })),
      finMaterialsHT: _.listof(_.record(selectOptionSchema, { optional: true })),
      finTypes: _.listof(_.record(selectOptionSchema, { optional: true })),
      finTypesLT: _.listof(_.record(selectOptionSchema, { optional: true })),
      finTypesHT: _.listof(_.record(selectOptionSchema, { optional: true })),
      refrigerants: _.listof(_.record(selectOptionSchema, { optional: true })),
      fans: _.listof(_.record(selectOptionSchema, { optional: true }))
    })
  }),
  key: _.number(),
  version: _.string()
}

export const CustomConfiguratorValidator = createSchema(CustomConfiguratorSchema)
