import { env, localStorageKeys } from '../config'
import store from '../lib/store'

store.removeExpiredKeys()

let token = store.get(localStorageKeys.authToken)
let roles = store.get(localStorageKeys.roles) || {}
let actions = store.get(localStorageKeys.actions) || {}

export const isAuth = () => !!token

export const getToken = () => token
export const getRoles = () => roles
export const getActions = () => actions
export const hasAction = action => actions[action] === true

export const setToken = newToken => {
  token = newToken
  store.set(localStorageKeys.authToken, newToken, store.setExpireTime())
}

export const setRoles = data => {
  roles = (data || []).reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})
  store.set(localStorageKeys.roles, roles, store.setExpireTime())
}

export const setActions = data => {
  actions = (data || []).reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {})

  if (env.isDevelop) actions['configurator.custom_coils'] = true

  store.set(localStorageKeys.actions, actions, store.setExpireTime())
}

export const invalidateAuth = () => {
  token = null
  roles = []
  actions = []
  store.remove(localStorageKeys.authToken)
  store.remove(localStorageKeys.roles)
  store.remove(localStorageKeys.actions)
}
