import m from 'mithril'
import Icon from '../common/icon/icon'
import cS from '../../models/configurator'
import { hasAction } from '../../models/auth'
import t from '../../lib/translate'

const ConfiguratorTabs = () => {
  function view ({ attrs }) {
    const cs = cS()
    return m('.tabs.configurator-tabs.is-fullwidth.is-toggle', [
      m('ul', [
        m('li', {
          className: attrs.app.section === 'configurator-wizard' && cs.ui.searchMode === 'bytype' ? 'is-active' : ''
        }, m('a', {
          href: '#',
          onclick (e) {
            e.preventDefault()
            cS.update({
              ui: { searchMode: 'bytype' }
            })
            m.route.set('/configurator-wizard/1')
          }
        }, [
          m(Icon, { name: 'faHandPointer' }),
          m('span.is-hidden-mobile.ml2', t('configurator.select_product_type'))
        ])),
        m('li', {
          className: attrs.app.section === 'configurator-wizard' && cs.ui.searchMode === 'byname' ? 'is-active' : ''
        }, m('a', {
          href: '#',
          onclick (e) {
            e.preventDefault()
            cS.update({
              ui: { searchMode: 'byname' }
            })
            m.route.set('/configurator-wizard/1')
          }
        }, [
          m(Icon, { name: 'faSearch' }),
          m('span.is-hidden-mobile.ml2', t('configurator.search_by_name'))
        ])),
        hasAction('configurator.custom_products') && m('li', {
          className: attrs.app.section === 'custom-configurator-wizard' ? 'is-active' : ''
        }, m(m.route.Link, {
          href: '/custom-configurator-wizard/1'
        }, [
          m(Icon, { name: 'faWrench' }),
          m('span.is-hidden-mobile.ml2', t('configurator.custom_product'))
        ])),
        hasAction('configurator.custom_coils') && m('li', {
          className: attrs.app.section === 'custom-coils-configurator-wizard' ? 'is-active' : ''
        }, m(m.route.Link, {
          href: '/custom-coils-configurator-wizard/1'
        }, [
          m(Icon, { name: 'faCog' }),
          m('span.is-hidden-mobile.ml2', t('configurator.custom_coil'))
        ])),
        hasAction('configurator.costing') && m('li', {
          className: attrs.app.section === 'costing-configurator-wizard' ? 'is-active' : ''
        }, m(m.route.Link, {
          href: '/costing-configurator-wizard/1'
        }, [
          m(Icon, { name: 'faFileInvoiceDollar' }),
          m('span.is-hidden-mobile.ml2', t('configurator.costing'))
        ]))
      ])
    ])
  }

  return { view }
}

export default ConfiguratorTabs
